body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-brown: #362b21;
  --primary-gold: #ffc700;
  --content-font-size: 18px;
}

body {
  background-color: var(--primary-brown);
  margin: 0;
  font-family: "Quattrocento", serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
blockquote,
p {
  color: white;
}

h5,
h6,
li,
p {
  font-family: "Fanwood Text", serif;
}

p,
li {
  font-size: var(--content-font-size);
}

.no-top-margin {
  margin-top: 0px;
}

.content-wrapper {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.content-column {
  margin-left: 50px;
  margin-right: 50px;
}

.haiku-wrapper {
  text-decoration: underline white;
  font-family: "Lobster", cursive;
  font-size: 2em;
  min-height: 6em;
  margin: 0 4em 0 4em;
}

@media only screen and (max-width: 800px) {
  .haiku-wrapper {
    margin: 0 10px 0 10px;
  }
}

.what {
  text-align: right;
}

.articles-list {
  margin: 50px 0px 50px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.article-box {
  margin: 0px 20px 0px 20px;
  height: 200px;
  width: calc(33% - 40px);
}

.articles-header {
    text-align: center;
}

.blog-link-wrapper {
    display: flex;
    justify-content: flex-end;
}

.blog-link {
    position: relative;
    bottom: 30px;
    right: 50px;
    font-size: calc(var(--content-font-size) * 1.5);
}

@media only screen and (max-width: 800px) {
  .article-box {
    margin: 20px 20px 20px 20px;
    height: 60vw;
    width: calc(100% - 40px);
  }

  .articles-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.article-image-box {
  height: 60%;
  overflow: hidden;
}

.article-image-box img {
  width: 100%;
  object-fit: cover;
}

.article-header {
  text-align: center;
}

a:visited {
  color: white;
}

.title {
  text-align: center;
}

.title-top {
  font-size: 72px;
}

.title-bottom {
  font-size: 48px;
}

@media only screen and (max-width: 800px) {
  .title-top {
    font-size: 48px;
  }

  .title-bottom {
    font-size: 32px;
  }
}

.short {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  position: relative;
  text-align: center;
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid var(--primary-gold);
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 12.5px;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: 10px solid var(--primary-brown);
}

.dot > img {
  padding-top: 3.5px;
}

.pulse-loader {
  animation: pulse 2s infinite linear;
}

.spin-container {
  display: flex;
  justify-content: center;
}

.spin-loader {
  max-width: 200px;
  animation: spin 5s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
