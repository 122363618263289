.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 72px;
}

.header {
  text-align: center;
}

.line {
  margin: 0 auto;
  width: 80%;
}

.responsive {
  width: 100%;
  height: auto;
}

.headerBottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.headerInfoRight {
  color: var(--primary-gold);
  font-size: 26px;
}

.ingress > p,
li {
  font-size: 24px;
  color: white;
}

.body > * > p {
  font-size: 24px;
}

.body > * > blockquote {
  font-style: italic;
  font-size: 32px;
  border-left: 5px solid var(--primary-gold);
  margin: 1.5em 10px;
  background-color: #55402d;
  padding: 0.5em 10px;
}

.noBlog {
  text-align: center;
  margin-bottom: 128px;
}

.authorImg {
  border-radius: 50%;
  margin-right: 18px;
}

.headerInfoLeft {
  display: flex;
  align-items: center;
}

.headerInfoLeft > div > p {
  margin: 0;
  text-align: left;
  color: var(--primary-gold);
}
